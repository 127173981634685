import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { _iconFilter } from '../../utils/functions'


const CardDisplay = ({transformedData}: any) => {



  return (
    <div className="mt-4">
      {transformedData?.map((company: any, index: number) => (
        <React.Fragment key={index}>
          {transformedData?.length > 1 && (
            <div className="bg-gray ff-agency text-green border border-2 border-gray rounded-pill  mt-2 mb-3 py-2">
              <div className="text-center ps-0">{company.companyName}</div>
            </div>
          )}
          {company?.locker?.map((lockers: any, indx: any) => (
            <React.Fragment key={indx}>
              <Card className="cursor border-2 border-gray p-3 mb-3 text-ui-second">
                <Row className="px-1 px-sm-3">
                  <Col className="m-auto ms-md- font-75 ps-2 px-0 mx-0 fs-5 text-md-center">
                    {lockers?.shortLocation}
                  </Col>
                  <Col>
                    {lockers?.temperatureZone?.map(
                      (slots: any, slotIndex: any) => (
                        <div
                          key={slotIndex}
                          className="pb-1"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <img
                            alt="Temp icon"
                            src={_iconFilter(slots?.keyTemp)}
                            style={{ width: "30px" }}
                          />
                          <span className="font-65 pt-2 ms-1 ">
                            <i className="ri-arrow-right-line"></i>
                          </span>
                          <div
                            key={Math.random()}
                            className="badge-hoster px-0 ms-1 pt-1"
                          >
                            <span className="font-75 fw-bold ms-2 mb-0 bg-warning rounded-pill  px-2">
                              {slots?.size}
                            </span>
                            <div className="my-badge px-0 font-65 fw-bold pb-0 text-light">
                              <span className="rounded-pill bg-green px-1 py-">
                                {slots?.available}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

export default CardDisplay
