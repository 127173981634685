import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./pages/Public/Auth";
import "animate.css";
import ScrollToTop from "./components/ui/ScrollToTop";
import HeaderDesign from "./components/layout/HeaderDesign";
import Prepared from "./pages/Prepared";
import InDelivery from "./pages/InDelivery";
import ToRetrieve from "./pages/ToRetrieve";
import Created from "./pages/Created";
import Operout from "./pages/Operout";
import NotFound from "./pages/NotFound";
import DashBoard from "./pages/DashBoard";
import Forgot from "./pages/Public/Forgot";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import HistoryClient from "./pages/Public/HistoryClient";
import "remixicon/fonts/remixicon.css";
import Preps from "./pages/Preps";
import LockerChoice from "./pages/LockerChoice";
import Delivered from "./pages/Delivered";
import NewOrder3 from "./pages/newOrder/NewOrder3";
import Availability from "./pages/Availability";
// import { PrivateRoute } from "./middleware/PrivateRoute";

const History = React.lazy(() => import("./pages/History/History"));
const NewOrder2 = React.lazy(() => import("./pages/newOrder/NewOrder2"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "preparations",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Chargement" />
              <Prepared />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),

      },
      {
        path: "dashboard",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Tableau de bord" />
              <DashBoard />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "lockers",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Lockers" />
              <LockerChoice />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "livraisons",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Livraisons" />
              <InDelivery />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "retraits",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="A récupérer" />
              <ToRetrieve />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },

      {
        path: "preps",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Préparations" />
              <Preps />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "deposees",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Commandes déposées" />
              <Delivered />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "creees",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Test" />
              <Created />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "retirées",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Commandes retirées" />
              <Operout />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },

      {
        path: "nouvelle-commande",
        element: (
          <React.Suspense>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Nouvelle commande" />
              <NewOrder3 />
            {/* </PrivateRoute> */}
          </React.Suspense>
        ),
      },
      {
        path: "historique",
        element: (
          <React.Suspense>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Historique" />
              <History />
            {/* </PrivateRoute> */}
          </React.Suspense>
        ),
      },
      {
        path: "update-password",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Mot de passe" />
              <UpdatePassword />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
      {
        path: "disponibilite",
        element: (
          <React.Fragment>
            {/* <PrivateRoute> */}
              <HeaderDesign title="Disponibilités" />
              <Availability />
            {/* </PrivateRoute> */}
          </React.Fragment>
        ),
      },
    ],
  },
  {
    path: "/connexion",
    element: <Auth />,
  },
  {
    path: "/forgot-password/:token",
    element: <Forgot />,
  },
  {
    path: "/commande/:id",
    element: <HistoryClient />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ScrollToTop />
  </React.StrictMode>
);
serviceWorkerRegistration.register();
reportWebVitals();
