import axios from "axios";


const API_URL = process.env.REACT_APP_END_POINT

class BookingSlotService {
    allSlot(token: any) {
        return axios.get(API_URL + "booking_slots?q[company][id][]&itemsPerPage=60&q[slot][temperatureZone][locker][]=id&q[available]&q[slot][depth]&q[slot][height]&q[slot][width]&q[slot][size]&q[company][cleveronCompanyId]&q[company][name]&q[slot][temperatureZone][locker][]=shortLocation&q[slot][temperatureZone][locker][location]&q[slot][temperatureZone][locker][type]&q[slot][temperatureZone][name]&q[slot][temperatureZone][keyTemp]&q[slot][temperatureZone][myKey]", {headers: {"Authorization": "Bearer " + token}})
    }
    
    slot(token: any, id: any) {
        return axios.get(API_URL + "booking_slots/" + id, {headers: {"Authorization": "Bearer " + token}})
    }
    
      
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BookingSlotService();