import React, { useRef } from 'react'
import '../App.css'
import 'animate.css'
import userDataStore, { UserState } from '../store/userDataStore'
import { Navigate, useOutletContext } from 'react-router-dom'
import { message } from 'antd'
import { Alert, Button, Container, Modal} from 'react-bootstrap'
import { _searchWithRegex } from '../utils/functions'
import SearchBar from '../components/ui/SearchBar'
import AlertIsError from '../components/ui/warning/AlertIsError'
import PlaceHolder from '../components/ui/loading/PlaceHolder'
import OrderList from '../components/ui/OrderList'
import OrderDetail from '../components/ui/OrderDetail'
import jsQR from 'jsqr'
import Anomaly from '../components/ui/anomaly/Anomaly'
import { OrdersType } from '../definitions/OrderType'


const Prepared: React.FC = () => {
  
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isNotAvailable, setIsNotAvailable] = React.useState<boolean>(false)

  //////////////////////////
  // Store & context state
  /////////////////////////
  const dataStore = userDataStore((state: UserState) => state)

  
  const {
    setSelectedStore,
    setSelectedOrderCity,
    allSlot,
    setSelectedItem,
    selectedStore,
    selectedOrderCity,
    orderReady,
    setOrderReady,
    setOrderPickedUp,
    setIsSelectedOrder,
    idStore, setIdStore
   }= useOutletContext<any>()




  
  //////////////////////////
  // States
  /////////////////////////

  const [messageApi, contextHolder] = message.useMessage()

  const [selectedOrder, setSelectedOrder] = React.useState<OrdersType | undefined>()
  const [searchOrder, setSearchOrder] = React.useState<string>('')
  const [filteredOrder, setFilteredOrder] = React.useState<any>([])
  const [storeName, setStoreName] = React.useState<any>([])
  const [lockerFiltered, setLockerFiltered] = React.useState<any>([]);
  const [lockerFull, setLockerFull] = React.useState<any>([]);

  const [isScan, setIsScan] = React.useState<boolean>(false)
  const videoRef = useRef<any>(null)
  const [scanCode, setScanCode] = React.useState<string>('')

  const [isAnomaly, setIsAnomaly] = React.useState<boolean>(false)
  const [msgAnomaly, setMsgAnomaly] = React.useState<string>('')
  
  let videoStream: MediaStream | null = null

  const trigger: string = 'preparations'
  const newStatus: string = 'picked_up'
  


  //////////////////////////
  // Available slot error msg
  //////////////////////////

  const [showFullLocker, setShowFullLocker] = React.useState<boolean>(false)

  const handleCloseFullLocker = () => setShowFullLocker(false)
  const handleShowFullLocker = () => setShowFullLocker(true)



  ///////////////////////////////////////////////////
  ////Filtrage des données par locker et par livreur
  //////////////////////////////////////////////////
  const orderByStatus = orderReady && orderReady["hydra:member"]
  ?.filter(
    (order: any) =>
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] === selectedStore
  );


  //////////////////////////
  // UseEffect
  /////////////////////////

  React.useEffect(() => {
    setIsLoading(true)
    setSelectedItem('preparations')
    setIsSelectedOrder(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    
    
  }, [setIsSelectedOrder, setSelectedItem])
  
  React.useEffect(() => {
   
    setLockerFiltered(allSlot?.["hydra:member"]?.filter((locker: any) => locker?.slot.temperatureZone.locker["@id"] === selectedStore))

    const full = allSlot?.["hydra:member"]?.filter((slotAvailable: any) => slotAvailable.available <= 0 )?.filter((locker: any) => locker?.slot.temperatureZone.locker["@id"] === selectedStore)
    
    // if(full?.length>0)
    //   {
    //     setIsNotAvailable(true)
    //     setLockerFull(full)
    //   }
  }, [allSlot, selectedStore])
  

  React.useEffect(() => {
    if (orderByStatus) {
      setIsLoading(false)
    } else {
      if ( orderByStatus?.length < 0) {
        setIsError(true)
        setIsLoading(false)
      }else{

        setIsLoading(true)
      }
    }
  }, [orderByStatus])

  React.useEffect(() => {
    const myScan = orderReady && orderReady['hydra:member']?.filter(
      (order: any) => order?.barcode === searchOrder || order?.externalOrderId === searchOrder
    )[0]

    if (myScan) {
      setScanCode(searchOrder);
      setIsSelectedOrder(true);

      handleCheckScanData(myScan, selectedStore);
    } else {
    // No scan found, handle search logic using _searchWithRegex
    _searchWithRegex(searchOrder, orderByStatus, setFilteredOrder);
  }
  
  }, [searchOrder])


  React.useEffect(() => {
    setStoreName(
      allSlot?.['hydra:member'] &&
        allSlot?.['hydra:member']?.filter(
          (locker: any) => locker?.slot?.temperatureZone?.locker['@id'] === selectedStore
        )[0]
    )
  }, [allSlot, selectedStore])

  

  const handleScan = async () => {
    setIsAnomaly(false)
    if (navigator?.mediaDevices) {
      setIsScan(true)

      try {
        const stream = await navigator?.mediaDevices?.getUserMedia({
          video: { facingMode: 'environment' },
        })
        videoStream = stream
        videoRef.current.srcObject = stream
        await videoRef.current!.play() // Attendre la lecture vidéo
        requestAnimationFrame(scanQRCode)
        setIsScan(true)
        console.log('success')
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    }
  }

  const stopScan = () => {
    videoStream?.getTracks()?.forEach((track: any) => track.stop())
    videoStream = null
    setIsScan(false)
  }

  const scanQRCode = () => {
    if (
      videoRef.current &&
      videoStream &&
      videoRef.current.videoWidth &&
      videoRef.current.videoHeight
    ) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)

      const imageData = context?.getImageData(0, 0, canvas.width, canvas.height)
      if (imageData) {
        const code = jsQR(imageData.data, imageData.width, imageData.height)

        if (code) {
          console.log('QR Code detected:', code.data)
          setScanCode(code.data)
          if (code.data === '') {
            setIsAnomaly(false)
            setIsScan(false)
            stopScan()
          } else {
            const myScan = orderReady['hydra:member']?.filter(
              (order: any) =>
                order?.barcode === code?.data || order?.externalOrderId === code?.data
            )[0]
            
            if (myScan) {
              //check if data scan is good
              handleCheckScanData(myScan, selectedStore)
            } else {
              //no exist
              setMsgAnomaly("Cette commande n'existe pas.")
              setIsAnomaly(true)
            }
          }
          stopScan()
        }
      }
      requestAnimationFrame(scanQRCode)
    }
  }


  //check if data scan is good
  const handleCheckScanData = (myScan:any, selectedStore: any) => {
   
      if (myScan.status === 'ready_for_delivery') {
        const lockerId = myScan.bookingSlot?.slot?.temperatureZone?.locker?.['@id'];
        if (lockerId && lockerId !== selectedStore) {
          setIsAnomaly(true);
          setMsgAnomaly('Commande pour : ' + myScan.bookingSlot?.slot?.temperatureZone?.locker?.location);
        } else {
          setSelectedOrder(myScan); // OK, no anomaly
        }
      } 
     
   
  }

  //////////////////////////
  // Component Props
  /////////////////////////

  const anomalyProps = {setSelectedOrder, setIsAnomaly, selectedOrder, scanCode, msgAnomaly}


  const searchBarProps = {
    searchOrder,
    setSearchOrder,
    selectedStore,
    setSelectedStore,
    selectedOrderCity,
    setSelectedOrderCity,
    allSlot,
    setIdStore,
  
  }

  const orderListProps = {
    filteredOrder,
    setSelectedOrder,
    searchOrder,
    setSearchOrder,
    orderByStatus,
    storeName,
    trigger,
    setIsSelectedOrder,
    isNotAvailable,
    handleShowFullLocker,
    lockerFull,
  }

 

  const scanPageProps = {
    selectedOrder,
    messageApi,
    setSelectedOrder,
    newStatus,
    setOrderReady,
    setOrderPickedUp,
    setSearchOrder,
    trigger
  }

 
  return (
    <>
      <Modal
        show={showFullLocker}
        onHide={handleCloseFullLocker}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title>
            <i className="ri-error-warning-line text-warning"></i>
            Indisponibilité
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            {lockerFull?.length !== lockerFiltered?.length ? (
              <>
                <span>Attention : </span>
                {lockerFull?.map((lock: any, indx: number) => (
                  <p className="font-85" key={indx}>
                    Le locker{" "}
                    <b>{lock?.slot?.temperatureZone?.locker?.shortLocation}</b>{" "}
                    en <b>zone {lock?.slot?.temperatureZone?.name}</b> est plein
                  </p>
                ))}
                <p className="font-85">
                  Aucun prise en charge n'est possible pour
                  {lockerFull?.length > 1 ? " ces zones" : " cette zone"}
                </p>
              </>
            ) : (
              <>
                <span className="font-75">
                  {" "}
                  <b className="font-85">Attention : </b>
                  <b className="text-ui-second ">
                    {
                      lockerFull[0]?.slot?.temperatureZone?.locker
                        ?.shortLocation
                    }
                  </b>{" "}
                  est plein.
                </span>
                <br />
                <span className="font-75">
                  Aucune prise en charge n'est possible pour ce locker.
                </span>
                <br />
                <p className="font-85 text-center mb-0 mt-2">
                  <b>Attendez la libération de caisers</b>
                </p>
              </>
            )}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="transparent"
            className="bg-yellow"
            onClick={handleCloseFullLocker}
          >
            J'ai compris
          </Button>
        </Modal.Footer>
      </Modal>
      {!selectedOrder && !isAnomaly && (
        <div className={`${!isScan ? "sticky-top pt-2" : "d-none"} bg-ui`}>
          <SearchBar searchBarProps={searchBarProps} />
        </div>
      )}
      <Container fluid className=" px-0 bg-ui">
        {contextHolder}
        {!dataStore.token && <Navigate to="/connexion" />}
        {isError ? (
          <Container className="text-center mt-5">
            <AlertIsError
              title="Une erreur s'est produite"
              msg="Vérifiez votre connexion internet ou contactez votre administrateur."
              colorIcon="danger"
            />
          </Container>
        ) : isLoading ? (
          <Container className="text-center mt-2">
            <PlaceHolder itemCount={10} />
          </Container>
        ) : (
          <>
            {isAnomaly ? (
              <Container fluid className="pb-5">
                <div className="col-12 text-center pt-2 font-75 text-light">
                  {storeName &&
                    storeName?.slot?.temperatureZone?.locker?.location}
                </div>
                <Anomaly anomalyProps={anomalyProps} />
              </Container>
            ) : !selectedOrder ? (
              <>
                <OrderList orderListProps={orderListProps} />
              </>
            ) : (
              <OrderDetail scanPageProps={scanPageProps} />
            )}
          </>
        )}
      </Container>
      {isScan && (
        <div className="video-container text-center">
          <video ref={videoRef} />
        </div>
      )}
      {!selectedOrder && (
        <Button
          aria-label="Aria Scan"
          title="scan"
          className={`fab rounded-circle ${
            isScan ? "bg-yellow" : "bg-green"
          }  border-0`}
          onClick={() => {
            if (isScan) {
              stopScan();
            } else {
              handleScan();
              setIsScan(true);
            }
          }}
          style={{ width: 55, height: 55 }}
        >
          <i
            className={`ri-${
              isScan ? "close-line" : "qr-code-line"
            }  align-bottom fs-2`}
          ></i>
        </Button>
      )}
    </>
  );
}

export default Prepared
