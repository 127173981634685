import React from "react";
import { Container, Navbar, Row, Col, Button } from "react-bootstrap";
import OrderService from "../../service/Orders/OrdersService";
import moment from "moment";
import { useParams } from "react-router-dom";
import userDataStore from "../../store/userDataStore";
import { _getStatus, _getStatusMsg } from "../../utils/functions";

const HistoryClient = () => {
  const dataStore = userDataStore((states: any) => states);
  const token = userDataStore((state: any) => state.token);
  const [myOrder, setMyOrder] = React.useState<any>([]);
  const [order, setOrder] = React.useState<any>([]);

  const params = useParams();

  React.useEffect(() => {
    getOrder(params.id, token);
    getAllOrder(dataStore.token);
  }, []);

  const getOrder = (id: any, token: any) => {
    OrderService.order(id, token)
      .then((response: any) => {
        setMyOrder(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const getAllOrder = (token: any) => {
    OrderService.allOrders(token)
      .then((response: any) => {
        setOrder(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const sortedHistory = myOrder?.history?.sort((a: any, b: any) => {
    const dateA: any = new Date(a.createdAt);
    const dateB: any = new Date(b.createdAt);
    return dateA - dateB;
  });
console.log(myOrder)

  return (
    <Container fluid className="px-0">
      <Container fluid className="top-nav-design text-green">
        <Row className="pe-0 full-row">
          <Col xs={2} className="py-2 align-bottom m-auto text-center"></Col>
          <Col
            xs={8}
            sm={8}
            className="header-title text-green m-auto text-center"
          >
            <b>Suivi de ta commande</b>
          </Col>
          <Col xs={2} className="py-2 m-auto  align-bottom text-center"></Col>
        </Row>
      </Container>
      {/* <Navbar bg='secondary' expand='lg'>
        <Container>
          <Navbar.Brand className='text-center text-info m-auto'>
            <div className='font-5 text-light'>{myOrder?.bookingSlot?.company?.name}</div>
            <div className='ff-agency font-5'>Suivi de votre commande</div>
          </Navbar.Brand>
        </Container>
      </Navbar> */}
      <Container className="text-center text-secondary fw-bold">
        {" "}
        N° : {myOrder?.barcode}
      </Container>
      <Container className="text-center text-secondary fw-bold">
        Client : {myOrder?.client?.name}
      </Container>
      <div className="history-tl-container animate__animated animate__backInLeft pb-5">
        <ul className="tl d-flex flex-column-reverse ">
          {myOrder &&
            myOrder?.history?.length > 0 &&
            sortedHistory?.map((order: any, indx: number) => (
              <li
                key={Math.random()}
                className={
                  myOrder?.history?.length - 1 === indx
                    ? "tl-item-current"
                    : "tl-item"
                }
                ng-repeat="item in retailer_history"
              >
                {order.status === "created" ? (
                  <div
                    className={
                      myOrder?.history?.length - 1 === indx
                        ? "timestamp-current fw-bold"
                        : "timestamp"
                    }
                  >
                    {moment(myOrder.createdAt).format("DD/MM/YY")}
                    <br /> {moment(myOrder.createdAt).format("HH:mm:ss")}
                  </div>
                ) : (
                  <div
                    className={
                      myOrder?.history?.length - 1 === indx
                        ? "timestamp-current fw-bold"
                        : "timestamp"
                    }
                  >
                    {moment(order.createdAt).format("DD/MM/YY")}
                    <br /> {moment(order.createdAt).format("HH:mm:ss")}
                  </div>
                )}
                <div
                  className={
                    myOrder?.history?.length - 1 === indx
                      ? "item-title-current"
                      : "item-title"
                  }
                >
                  {_getStatus(order.status)}
                </div>
                <div
                  className={
                    myOrder?.history?.length - 1 === indx
                      ? "item-detail-current fw-bold"
                      : "item-detail"
                  }
                >
                  {" "}
                  {_getStatusMsg(order.status)}
                </div>
                <div className="item-detail">
                  {" "}
                  {(order.status === "picked_up" ||
                    order.status === "operin") && (
                    <p>
                      Livreur : {myOrder?.shippedBy?.firstName}{" "}
                      {myOrder?.shippedBy?.lastName}
                    </p>
                  )}{" "}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </Container>
  );
};

export default HistoryClient;
