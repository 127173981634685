import React from 'react'
import { useOutletContext } from 'react-router-dom';
import LokcerCard from '../components/newOrder/LockerCard';
import { Container } from 'react-bootstrap';
import CardDisplay from '../components/availability/CardDsiplay';

export default function Availability() {
    const {
       
        allSlot,
       
      } = useOutletContext<any>();

      const [transformedData, setTransformedData] = React.useState<any>([]);
      const [trigger, setTrigger] = React.useState<any>();
      const [chosenLocker, setChosenLocker] = React.useState<any>([]);
      const [ageRestriction, setAgeRestriction] = React.useState<boolean>(false);



      const punaAvailability = allSlot?.["hydra:member"]?.filter((dispo: any) => dispo?.company.id === 1) 
      const arueAvailability = allSlot?.["hydra:member"]?.filter((dispo: any) => dispo?.company.id === 2) 
      const faaaAvailability = allSlot?.["hydra:member"]?.filter((dispo: any) => dispo?.company.id === 3) 
      const onwineAvailability = allSlot?.["hydra:member"]?.filter((dispo: any) => dispo?.company.id === 4) 

      const [available, setAvailable] = React.useState<any[]>([punaAvailability, arueAvailability, faaaAvailability, onwineAvailability]);
      
      const [bookingGroup, setbookingGroup] = React.useState<any>([]);
      const [slotGroup, setSlotGroup] = React.useState<any>([]);

      React.useEffect(() => {
        if (allSlot && allSlot["hydra:member"]?.length > 0) {
          setbookingGroup([...new Set(allSlot?.["hydra:member"]?.map((slot: any) => slot.company.name ))]) ;
        } 
        
       
        
      }, [allSlot]);
      React.useEffect(() => {
        
       let newTab: any = [];
        bookingGroup.forEach((booking: any, indx: number) => {
          const filtered = allSlot && allSlot["hydra:member"].filter((slot: any) => slot.company.name === booking)
           newTab = [...newTab, filtered]
        })
        setSlotGroup(newTab);
    
        
      }, [bookingGroup]);
    
    
      React.useEffect(() => {
        setTransformedData(transformData(slotGroup));
      }, [slotGroup]);
    
      const transformData = (data: any) => {
        const result: any = [];
      
        const companyMap: any = {};
      
        data.flat().forEach((bookingSlot: any) => {
          const companyName = bookingSlot.company.name;
          const locker = bookingSlot.slot.temperatureZone.locker;
          const temperatureZone = {
            "@id": bookingSlot["@id"],
            "myKey": bookingSlot.slot.temperatureZone.myKey,
            "name": bookingSlot.slot.temperatureZone.name,
            "keyTemp": bookingSlot.slot.temperatureZone.keyTemp,
            "size": bookingSlot.slot.size,
            "available": bookingSlot.available
          };
      
          if (!companyMap[companyName]) {
            companyMap[companyName] = {
              companyName,
              locker: {}
            };
          }
      
          const lockerId = locker["@id"];
          if (!companyMap[companyName].locker[lockerId]) {
            companyMap[companyName].locker[lockerId] = {
              "@id": lockerId,
              "type": locker.type,
              "location": locker.location,
              "shortLocation": locker.shortLocation,
              "temperatureZone": []
            };
          }
      
          companyMap[companyName].locker[lockerId].temperatureZone.push(temperatureZone);
        });
      
        Object.values(companyMap).forEach((company: any) => {
          company.locker = Object.values(company.locker);
          result.push(company);
        });
      
        return result;
      };



  return (
    <Container>
      
      <CardDisplay transformedData={transformedData} />
    </Container>
  );
}
